export const createPlaceholderImage = (width: number, height: number, text?: string): string => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Canvas 크기 설정
  canvas.width = width < 300 ? width : 300;
  canvas.height = width < 300 ? height : (300 / width) * (height ?? width);

  // 배경색 설정
  ctx.fillStyle = "#cccccc";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // 텍스트 스타일 설정
  ctx.fillStyle = "#666666";
  ctx.font = "16px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";

  // 텍스트 그리기
  const displayText = text || `${width} x ${height || width}`;
  ctx.fillText(displayText, canvas.width / 2, canvas.height / 2);

  // Canvas를 데이터 URL로 변환
  return canvas.toDataURL("image/png");
};

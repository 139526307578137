import { createSlice } from "@reduxjs/toolkit";

export const name = "main";
const slice = createSlice({
  name,
  initialState: {
    // qna 관련
    qnaData: {
      prepaymentQnaCount: 0,
      talkQnaCount: 0,
    },
    // (일반) 이벤트
    eventData: {
      ingEvent: 0,
      eventAsk: 0,
      currentMoney: 0,
      recharge_money: 0,
      eventChartData: [],
    },
    // 미리결제 이벤트
    prepaymentData: {
      ad_event_cnt: 0,
      need_regist_event: 0,
      this_month_roas: 0,
      expected_payback: 0,
      this_month_payback: 0,
      this_month_payback_roas: 0,
      this_month_payback_babitalk: 0,
      this_month_discount_voucher_money: 0,
      this_month_discount_voucher_count: 0,
      prepaymentChartData: [],
    },
    // 공지
    notices: [],
    essentialNotices: null,
  },
  reducers: {
    getOverview: () => {},
    setOverview: (state, action) => {
      const {
        eventing,
        event,
        money,
        prepayment_event_qna_count,
        talk_qna_count,
        recharge_money,
        first_visit_button,
      } = action.payload;
      state.eventData.ingEvent = eventing;
      state.eventData.eventAsk = event;
      state.eventData.currentMoney = money;
      state.eventData.recharge_money = recharge_money;
      state.eventData.isFirstVisit = first_visit_button;

      state.qnaData.prepaymentQnaCount = prepayment_event_qna_count;
      state.qnaData.talkQnaCount = talk_qna_count;
    },
    getEventChart: () => {},
    setEventChart: (state, action) => {
      state.eventData.eventChartData = action.payload;
    },

    getOverviewPrepayment: () => {},
    setOverviewPrepayment: (state, { payload }) => {
      state.prepaymentData = {
        ...state.prepaymentData,
        ...payload,
      };
    },
    getPrepaymentChart: () => {},
    setPrepaymentChart: (state, { payload }) => {
      state.prepaymentData.prepaymentChartData = payload;
    },

    getNoticeList: () => {},
    setNoticeList: (state, action) => {
      state.notices = action.payload;
    },
    getNoticePopup: () => {},
    setNoticePopup: (state, action) => {
      state.essentialNotices = action.payload;
    },
    resetEssentialNotices: state => {
      state.essentialNotices = [];
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
